<script>
import { mapGetters } from 'vuex'
//import api from '@/services/secureHttps';

export default {
  name: 'TreeItem', // necessary for self-reference
  emits: {
    'featureChanged'(eventParamObj) {
      console.log('event val', eventParamObj);
      return eventParamObj;
    }
  },
  props: {
    model: Object,
    depth: Number,
    featureList: Array
  },

  data() {
    return {
      // isOpen: false,
      data: this.model
    }
  },

  mounted() {

    console.log("this.data:", this.data);
  },
  computed: {
    ...mapGetters('context', [
      'selectedWebisteId'
    ]),
    isFolder() {
      console.log("isFolder: ", this.data);
      return this.data.metaDataList && this.data.metaDataList.length;
    }
  },

  methods: {
    toggle() {
      console.log("toggle", this.isFolder);
      console.log(this.data);
      if (this.isFolder) {

        this.data.isOpen = !this.data.isOpen;
        this.data.metaDataList.forEach(element => {
          element.isVisible = !element.isVisible;
        });
      } else {
        console.log("fetch data");

        // this.fetchPageInfo(metaDataId);
      }
    },
    changeType() {
      if (!this.isFolder) {
        this.data.metaDataList = [];
        this.data.isOpen = true;
      }
    },

    handleOnPageSelected(pageInfo) {
      this.$emit('onPageSelected', pageInfo);
    },

    getFeatureLabel(featureObj) {
      if (typeof featureObj === 'undefined') {
        return "No set";
      }

      return featureObj.label;
    },

    getStatusLabel(status) {
      if (typeof status === 'undefined') {
        return "Not generated";
      }

      return status;
    },

    paddBasedOnDepth() {
      if (this.depth == 1) {
        return '';
      }

      var style = `padding-left:` + (this.depth + 0.5) + 'em';
      console.log(style);

      return style;
    },

    getSelectedFeature(featureObj) {
      if (this.data.feature) {
        return featureObj.id == this.data.feature.id
      }

      return false;

    },

    onFeatureChanged(e, selectedMetaDataObj) {
      console.log(e.target.value);
      console.log(this.isFolder);
      if (this.isFolder) {
        this.data.metaDataList.forEach(x =>  {
          var selectedFeature = this.featureList.find(x => x.id === parseInt(e.target.value));
          x.feature = selectedFeature;
          this.$emit('featureChanged', { selectedMetaDataObj: x, selectedFeatureObj: selectedFeature });
        });
      } else {
        var selectedFeature = this.featureList.find(x => x.id === parseInt(e.target.value));
        this.$emit('featureChanged', { selectedMetaDataObj: selectedMetaDataObj, selectedFeatureObj: selectedFeature });
      }



    
    },

    onFeatureChangedEvent(eventParamObj) {

      this.$emit('featureChanged', eventParamObj);
    }
  }
}
</script>

<template>
  <tr v-show="data.isVisible" id="1">
    <td>

      <div :class="[isFolder ? 'fw-medium' : 'text-muted']" @click="toggle(model.metaDataId)" @dblclick="changeType"
        class="highlightHover" :style="paddBasedOnDepth()">

        <span v-if="isFolder"><span v-if="data.isOpen" class="mdi mdi-chevron-down"></span><span v-else
            class="mdi mdi-chevron-right"></span> </span>

        {{ data.name }}
      </div>
    </td>
    <td>
      <select v-if="featureList" class="form-select" @change="onFeatureChanged($event, model)"
        :disabled="typeof data.status !== 'undefined'">
        <option value="notSet" :selected="typeof this.data.feature === 'undefined'">Select page feature</option>
        <option v-for="feature in featureList" :key="feature.id" :selected="getSelectedFeature(feature)"
          :value="feature.id">
          {{ feature.label }}</option>
      </select>
    </td>
    <td>
      {{ getStatusLabel(data.status) }}
    </td>
  </tr>

  <template v-if="data.isFolder" id="">
    <!--
        A component can recursively render itself using its
        "name" option (inferred from filename if using SFC)
      -->
    <TreeItem v-show="data.isOpen" v-for="model in data.metaDataList" :model="model" :key="model.metaDataId"
      :depth="depth + 1" :featureList="featureList" @featureChanged="onFeatureChangedEvent">
    </TreeItem>
  </template>
</template>

<style scoped>
.basicList {
  list-style-type: none;
}

.noBorder {
  border: none
}

.highlightHover:hover {
  text-decoration: underline;
}

.icon {
  text-decoration: none;
  float: right;
}
</style>

