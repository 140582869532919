<script>
import TreeItem from './tree-item.vue'
import api from '@/services/secureHttps';

export default {
    emits: {
        'featureChanged'(selectedMetaDataObj) {
            return selectedMetaDataObj;
        }
    },
    components: {
        TreeItem,
    },

    props: {
        websiteId: {
            type: Number,
            required: true,
        }
    },

    data() {
        return {
            website: null,
            queryValue: '',
            featureList: []

        }
    },

    created() {
        this.fetchData();
    },

    watch: {
        websiteId: function () {
            this.fetchData();
        }
    },

    methods: {
        async fetchData() {
            this.website = null;

            api.websiteFeatureList()
                .then(response => {
                    this.featureList = response.data.features;
                    console.log("featureList", this.featureList);
                })

            api.getWebsiteMetaData(this.websiteId, null, null, true)
                .then(response => {
                    console.log("getWebsiteMetaData", response);
                    this.website = response.data.metaDataTreeView;
                    console.log("website data", this.website);
                    this.website.isVisible = false;
                    this.website.isOpen = true;
                    this.website.isFolder = true;
                    this.setupWebsiteData(this.website.metaDataList, true);
                    console.log(this.website);
                })
                .catch(e => {
                    console.log(e);
                })
        },

        queryForPages(event) {
            const value = event.target.value;
            this.queryValue = value.toLowerCase();

            if (this.queryValue.length <= 2) {
                this.resetToAllVisible(this.website.metaDataList);
                return;
            }

            this.filterItems(this.website.metaDataList);
        },

        filterItems(pages) {
            var hasVisibility = false;

            for (let i = 0; i < pages.length; i++) {
                let item = pages[i];
                var isVisible = false

                if (item.name.toLowerCase().includes(this.queryValue)) {
                    isVisible = true;
                    hasVisibility = true;
                }

                item.isVisible = isVisible;

                if (typeof item.metaDataList !== 'undefined') {
                    var isParentNodeVisible = this.filterItems(item.metaDataList);
                    if (hasVisibility === false) {
                        hasVisibility = isParentNodeVisible;
                    }
                    item.isVisible = isParentNodeVisible;
                    item.isOpen = isParentNodeVisible;
                }
            }

            return hasVisibility;
        },

        setupWebsiteData(pages, isVisible) {
            console.log("setupWebsiteData");
            for (let i = 0; i < pages.length; i++) {
                console.log("page:", pages[i])
                let item = pages[i];
                item.isVisible = isVisible;

                if (typeof item.metaDataList !== 'undefined') {
                    item.isFolder = true;
                    item.isOpen = false;

                    this.setupWebsiteData(item.metaDataList, false);
                }
            }
        },

        resetToAllVisible(pages) {
            for (let i = 0; i < pages.length; i++) {
                let item = pages[i];
                item.isVisible = true;

                if (typeof item.metaDataList !== 'undefined') {
                    item.isOpen = false;
                    this.resetToAllVisible(item.metaDataList);
                }
            }
        },

        onFeatureChanged(selectedMetaDataObj) {
            console.log("nested search itemObj", selectedMetaDataObj);
            this.$emit('featureChanged', selectedMetaDataObj);
        }
    }
}

</script>

<template>
    <div class="row">
        <div class="col-xl-12">
            <input type="text" class="form-control" @keyup="queryForPages" placeholder="Search for a page">
        </div>

        <div class="row">
            <div class="col-xl-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-xl-12">

                <!-- end card header -->
                <div class="card-body p-0 pb-2">

                    <div class="table-responsive table-card m-1">
                        <table class="table align-middle table-nowrap">
                            <thead class="table-light text-muted">
                                <tr>
                                    <th scope="col">Page</th>
                                    <th class="sort" scope="col">Feature</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <!--end thead-->
                            <tbody class="list form-check-all">

                                <TreeItem class="item" v-if="website" :model="website" :depth="0"
                                    :featureList="featureList" @featureChanged="onFeatureChanged"></TreeItem>


                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>
.basicList {
    list-style-type: none;
}
</style>

