<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MetaDataSearch from "@/components/metadata/search/nested-search.vue";
import api from '@/services/secureHttps';

import { mapGetters } from 'vuex';

export default {
    components: {
        Layout,
        PageHeader,
        MetaDataSearch
    },

    data() {
        return {
            title: "Meta Data",
            items: [],
            websiteMetaDataItems: [],
            selectedFeature: null,
            features: [],
            metaDataStatus: null,
            updatedPages: [],
            hasDataChanged: false

        };
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId'
        ])
    },

    methods: {

        onFeatureChanged(selectedMetaDataObj) {
            console.log('autoExtract: ', selectedMetaDataObj);
            console.log('exsiting updates', this.updatedPages);
            var pageIndex = this.updatedPages.findIndex(x => x.selectedMetaDataObj.page.id === selectedMetaDataObj.selectedMetaDataObj.page.id);
            console.log(pageIndex);
            if (pageIndex >= 0) {
                console.log("updating index", pageIndex)
                this.updatedPages[pageIndex] = selectedMetaDataObj
            } else {
                console.log("adding metadataobj", selectedMetaDataObj);
                this.updatedPages.push(selectedMetaDataObj);
            }

            this.hasDataChanged = true;
        },

        saveChanges() {
            console.log("saveChanges", this.updatedPages[0]);
            var dataList = []

            this.updatedPages.forEach(x => {
                var data = {};
                data.websiteId = this.selectedWebisteId;
                data.webpageId = x.selectedMetaDataObj.page.id;
                data.featureId = x.selectedFeatureObj.id;
                data.metaData = null;

                console.log(data);

                dataList.push(data);
            })

            console.log(dataList);

            api.batchAddWebPagMetaData(dataList)
                .then(response => {
                    console.log(response);

                    this.hasDataChanged = false;
                    this.updatedPages = [];
                });
        },

        startAutoExtraction() {
            api.autoGenerateMetaData(this.selectedWebisteId);
        }
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header align-items-center">
                        <h3>Auto Extract Instructions</h3>
                    </div>
                    <!-- end card header -->
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-9">
                                <p>Automate the strucutred data for your page</p>
                                <ol>
                                    <li>Tag your pages to a feature type</li>
                                    <li>Press auto extract button and wait for the results.</li>
                                </ol>
                            </div>

                        </div>
                    </div>

                    <!--end card body-->
                </div>
                <!--end card-->
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header align-items-center">
                        <h3>Step 1. Tag page it's feature type:</h3>
                    </div>
                    <!-- end card header -->
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-xl-12">
                                <p>By tagging a page with a feature type (Article, Product, FAQ page etc). This will
                                    allow us to create the correct meta data for your page.
                                </p>
                            </div>
                        </div>
                        <MetaDataSearch :websiteId="selectedWebisteId" @featureChanged="onFeatureChanged">
                        </MetaDataSearch>

                        <div class="row">
                            <div class="col-xl-12">
                                <button v-show="hasDataChanged" @click="saveChanges()"
                                    class="btn btn-outline-success">Save changes</button>
                            </div>
                        </div>
                    </div>

                    <!--end card body-->
                </div>
                <!--end card-->
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header align-items-center">
                        <h3>Step 2. Auto extract:</h3>
                    </div>
                    <!-- end card header -->
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-9">
                                <p>Once you have tagged your pages with a feature (above). Now it's time to start
                                    generating structured data for your site.</p>
                            </div>
                            <div class="col-3">
                                <div class="align-items-end justify-content-end">
                                    <div>
                                        <button @click="startAutoExtraction()" class="btn btn-primary">Start Auto
                                            Extraction</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!--end card body-->
                </div>
                <!--end card-->
            </div>
        </div>
    </Layout>
</template>

<style scoped>
ol {
    list-style-type: none;
    counter-reset: elementcounter;
    padding-left: 0;
}

li:before {
    content: "Step " counter(elementcounter) ". ";
    counter-increment: elementcounter;
    font-weight: bold;
}
</style>
